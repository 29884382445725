import './App.css';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import React from 'react';
//FUNÇÃO STATUS QA1
  // Função para atualizar a página com o total de instâncias
function updatetotalInstancesQa1(totalQa1) {
    const totalInstancesQa1Element = document.getElementById("totalInstancesQa1");
    totalInstancesQa1Element.textContent = totalQa1;

// Atualizar a cor do banner com base no valor recebido
const bannerQa1 = document.getElementById("bannerQa1");
    if (totalQa1 > 6) {
        bannerQa1.style.backgroundColor = "green";
        bannerQa1.querySelector("p#statusQa1").textContent = "Normal";
    } else {
        bannerQa1.style.backgroundColor = "red";
        bannerQa1.querySelector("p#statusQa1").textContent = "Faltando";
    }
}

// Função para atualizar o status do ambiente
function fetchtotalInstancesQa1() {
    const messageAreaQa1 = document.getElementById("messageAreaQa1");
    messageAreaQa1.textContent = "Atualizando status, aguarde..."; // Mensagem de carregamento
    
    fetch('https://tz2olrvthbmlvukzjgz5vwhexu0plvhr.lambda-url.us-east-1.on.aws')
        .then(response => {
            if (!response.ok) {
                throw new Error('Erro ao buscar total de instâncias.');
            }
            return response.json();
        })
        .then(data => {
            const totalInstancesQa1 = data.total_instances;
            updatetotalInstancesQa1(totalInstancesQa1);
            messageAreaQa1.textContent = "Atualização concluída com sucesso.";
        })
        .catch(error => {
            console.error('Erro ao atualizar o status do ambiente.');
            messageAreaQa1.textContent = "Erro ao atualizar o status do ambiente.";
        });
}
// Chamar a função para obter o total de instâncias quando a página carregar
window.addEventListener('load', fetchtotalInstancesQa1);


//FUNÇÃO STATUS QA2

      // Função para atualizar a página com o total de instâncias
function updatetotalInstancesQa2(totalQa2) {
    const totalInstancesQa2Element = document.getElementById("totalInstancesQa2");
          totalInstancesQa2Element.textContent = totalQa2;

    // Atualizar a cor do banner com base no valor recebido
    const bannerQa2 = document.getElementById("bannerQa2");
      if (totalQa2 > 6) {
            bannerQa2.style.backgroundColor = "green";
            bannerQa2.querySelector("p#statusQa2").textContent = "Normal";
            } else {
              bannerQa2.style.backgroundColor = "red";
              bannerQa2.querySelector("p#statusQa2").textContent = "Faltando";
            }
        }

    // Função para atualizar o status do ambiente
function fetchtotalInstancesQa2() {
    const messageAreaQa2 = document.getElementById("messageAreaQa2");
    messageAreaQa2.textContent = "Atualizando status, aguarde..."; // Mensagem de carregamento
    fetch('https://vh4qmdnlnrzi2agurixspdivci0hqzqq.lambda-url.us-east-1.on.aws/')
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Erro ao buscar total de instâncias.');
                    }
                    return response.json();
                })
                .then(data => {
                    const totalInstancesQa2 = data.total_instances;
                    updatetotalInstancesQa2(totalInstancesQa2);
                    messageAreaQa2.textContent = "Atualização concluída com sucesso.";
                })
                .catch(error => {
                    console.error('Erro ao atualizar o status do ambiente.');
                    messageAreaQa2.textContent = "Erro ao atualizar o status do ambiente.";
                });
    }

    // Chamar a função para obter o total de instâncias quando a página carregar
window.addEventListener('load', fetchtotalInstancesQa2);



// FUNÇÃO STATUS INSTANCIAS SETUP DEV:
         // Função para atualizar a página com o total de instâncias
         function fetchTotalInstances() {
            const messageArea = document.getElementById("messageArea");
            messageArea.textContent = "Atualizando status, aguarde..."; // Mensagem de carregamento
            
            fetch('https://67jcdmwvwe34o7jpay26nrtdce0wckyp.lambda-url.us-east-1.on.aws/')
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Erro ao buscar total de instâncias.');
                    }
                    return response.json();
                })
                .then(data => {
                    const totalInstances = data.total_instances;
                    updateTotalInstances(totalInstances);
                    messageArea.textContent = "Atualização concluída com sucesso.";
                })
                .catch(error => {
                    console.error('Erro ao atualizar o status do ambiente.');
                    messageArea.textContent = "Erro ao atualizar o status do ambiente.";
                });
        }

        // Função para atualizar a página com o total de instâncias
        function updateTotalInstances(total) {
            const totalInstancesElement = document.getElementById("totalInstances");
            totalInstancesElement.textContent = total;
            
            // Atualizar a cor do banner com base no valor recebido
            const banner = document.getElementById("banner");
            if (total > 6) {
                banner.style.backgroundColor = "green";
                banner.querySelector("p#status").textContent = "Normal";
            } else {
                banner.style.backgroundColor = "red";
                banner.querySelector("p#status").textContent = "Faltando";
            }
        }
        
        // Chamar a função para obter o total de instâncias quando a página carregar
        window.addEventListener('load', fetchTotalInstances);

// FUNCAO SETUP DEV
function updateAutoScalingDev(executordev) {
    const desiredCapacityDev = document.getElementById("desiredCapacityDev").value;
            document.querySelector("#resultadoDev").innerHTML = '';
            alert("Comando recebido aguarde.");
            document.getElementById("desiredCapacityDev").disabled = true;
        
        // Chame a função Lambda
      const lambdaParams = {
                desiredCapacityDev: parseInt(desiredCapacityDev),
                usernamedev: executordev
            };

            // Faça uma solicitação AJAX para a função Lambda
            fetch('https://qy6sdbsrk1.execute-api.us-east-1.amazonaws.com/prd/PowerOnSetupHml', {
                method: 'POST',
                body: JSON.stringify(lambdaParams),
            })
            .then(response => {
                if (response.ok) {
                    alert('Configurações recebidas no ASG do Setup Dev- Aguarde,  isso pode levar cerca de 10min ou mais...');
                } else {
                    alert('Erro ao atualizar configurações do Setup Dev. Acione a equipe de DevOps!');
                }
            })
            .catch(error => {
                alert('Erro ao atualizar configurações: ' + error.message);
            });
  }


//FUNÇÃO STATUS RC
  // Função para atualizar a página com o total de instâncias
  function updatetotalInstancesRC(totalRC) {
    const totalInstancesRCElement = document.getElementById("totalInstancesRC");
    totalInstancesRCElement.textContent = totalRC;


    // Atualizar a cor do banner com base no valor recebido
const bannerRC = document.getElementById("bannerRC");
if (totalRC > 6) {
    bannerRC.style.backgroundColor = "green";
    bannerRC.querySelector("p#statusRC").textContent = "Normal";
} else {
    bannerRC.style.backgroundColor = "red";
    bannerRC.querySelector("p#statusRC").textContent = "Faltando";
}
}


// Função para atualizar o status do ambiente
function fetchtotalInstancesRC() {
  const messageAreaRC = document.getElementById("messageAreaRC");
  messageAreaRC.textContent = "Atualizando status, aguarde..."; // Mensagem de carregamento
  
  fetch('https://kgbjlvsjdaxzkmna6szw26k6w40axwym.lambda-url.us-east-1.on.aws')
      .then(response => {
          if (!response.ok) {
              throw new Error('Erro ao buscar total de instâncias.');
          }
          return response.json();
      })
      .then(data => {
          const totalInstancesRC = data.total_instances;
          updatetotalInstancesRC(totalInstancesRC);
          messageAreaRC.textContent = "Atualização concluída com sucesso.";
      })
      .catch(error => {
          console.error('Erro ao atualizar o status do ambiente.');
          messageAreaRC.textContent = "Erro ao atualizar o status do ambiente.";
      });
}


// Chamar a função para obter o total de instâncias quando a página carregar
window.addEventListener('load', fetchtotalInstancesRC);

////////-----------------------------------------------------------------------------//////


// FUNÇÃO SETUP LIGAR/DESLIGAR QA1

        function updateAutoScalingQa1(executor) {
            const desiredCapacityQa1 = document.getElementById("desiredCapacityQa1").value;
            document.querySelector("#resultadoQa1").innerHTML = '';
            alert("Comando recebido aguarde.");
            document.getElementById("desiredCapacityQa1").disabled = true;
            
        
        // Chame a função Lambda
            const lambdaParams = {
                desiredCapacityQa1: parseInt(desiredCapacityQa1),
                username: executor
            };

            // Faça uma solicitação AJAX para a função Lambda
            fetch(' https://5vxzztr4ji.execute-api.us-east-1.amazonaws.com/prd/PowerOnSetupHML', {
                method: 'POST',
                body: JSON.stringify(lambdaParams),
            })
            .then(response => {
                if (response.ok) {
                    alert('Configurações recebidas no ASG do Setup QA1- Aguarde,  isso pode levar cerca de 10min ou mais...');
                } else {
                    alert('Erro ao atualizar configurações do Setup QA1. Acione a equipe de DevOps!');
                }
            })
            .catch(error => {
                alert('Erro ao atualizar configurações: ' + error.message);
            });
        }

//FUNÇÃO SETUP QA2 

        function updateAutoScalingQa2(executorqa2) {
            const desiredCapacity = document.getElementById("desiredCapacity").value;
            document.querySelector("#resultadoQa2").innerHTML = '';
            alert("Comando recebido aguarde.");
            document.getElementById("desiredCapacity").disabled = true;
        
        // Chame a função Lambda
            const lambdaParams = {
                desiredCapacity: parseInt(desiredCapacity),
                usernameqa2: executorqa2
            };

            // Faça uma solicitação AJAX para a função Lambda
            fetch('https://8lle7rusm9.execute-api.us-east-1.amazonaws.com/prd', {
                method: 'POST',
                body: JSON.stringify(lambdaParams),
            })
            .then(response => {
                if (response.ok) {
                    alert('Configurações recebidas no ASG do Setup QA2- Aguarde, isso pode levar cerca de 10min ou mais...');
                } else {
                    alert('Erro ao atualizar configurações do Setup QA2 - acione a equipe de DevOps');
                }
            })
            .catch(error => {
                alert('Erro ao atualizar configurações: ' + error.message);
            });
        }

// FUNÇÃO SETUP LIGAR/DESLIGAR RC

function updateAutoScalingRC(executor) {
  const desiredCapacityRC = document.getElementById("desiredCapacityRC").value;
  document.querySelector("#resultadoRC").innerHTML = '';
  alert("Comando recebido aguarde.");
  document.getElementById("desiredCapacityRC").disabled = true;

// Chame a função Lambda
  const lambdaParams = {
      desiredCapacityRC: parseInt(desiredCapacityRC),
      usernamerc: executor
  };

  // Faça uma solicitação AJAX para a função Lambda
  fetch('https://83u8b1a30h.execute-api.us-east-1.amazonaws.com/default/PowerOnSetup', {
      method: 'POST',
      body: JSON.stringify(lambdaParams),
  })
  .then(response => {
      if (response.ok) {
          alert('Configurações recebidas no ASG do Setup RC- Aguarde, isso pode levar cerca de 10min ou mais...');
      } else {
          alert('Erro ao atualizar configurações do Setup RC. Acione a equipe de DevOps!');
      }
  })
  .catch(error => {
      alert('Erro ao atualizar configurações: ' + error.message);
  });
}

function openUrlQa1() {
  window.open('https://qa1.abaris.com.br/Account/Login', '_blank');
    }
function openUrlQa2() {
  window.open('https://qa2.abaris.com.br/Account/Login', '_blank');
    }
function openUrlDev() {
  window.open('https://dev.abaris.com.br/Account/Login', '_blank');
    }

function openUrlRC() {
    window.open('https://rc.abaris.com.br/Account/Login', '_blank');
    }
    

function App() {
  

  return (
    <Authenticator>
      
    {({ signOut, user }) => (
      

      <div className="App">

        <nav id="navbar">
                  <h2>
                      Start-Stop Ambientes de HML
                  </h2>
              
                  <button type="submit" onClick={signOut} >
                      Sair
                  </button>
                
        </nav>
        <div className='titulo'>
          <p>Olá, {user.attributes.nickname || user.attributes.email} ! </p>
          <p>Ambientes:</p>
        </div>
    
        <div className="setupdemo"> <b>Setup QA1: </b>
          <table>
            <tr>
              <td>
                <div className="container">
                  <p>Total de Instâncias on no Setup Qa1:</p>
                  <span id="totalInstancesQa1">Aguardando...</span>
                </div>
              </td>
              <td>
                <div className="container">
                  <div id="bannerQa1" className="small-banner">
                    <p>Numero de instancias:</p>
                    <p id="statusQa1">Aguardando...</p>
                  </div>
                </div>
              </td>
              <td>
                {/* Botão de atualização */}
                <button
                  id="updateButtonQa1"
                  onClick={fetchtotalInstancesQa1}
                  className="button"
                >
                  Atualizar Status
                </button>
                {/* Área de mensagem */}
                <div id="messageAreaQa1"></div>
              </td>
              <td>
                <button
                  id="openUrlQa1"
                  onClick={openUrlQa1}
                  className="button"
                >
                Open Qa1
                </button>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td><div className="container2">
                <form id="configFormQa1">
                  <select id="desiredCapacityQa1" name="desiredCapacityQa1">
                     <option value="1">Ligar</option>
                      <option value="0">Desligar</option>
                  </select></form></div>
              </td>
              <td>
                <button type="button" onClick={() => updateAutoScalingQa1(user.attributes.email)}>Enviar</button> 
              </td>
            </tr>
          </table>
      <p><span id="resultadoQa1"></span></p>
    </div>  
      <div className="setupdemo">  <b>Setup QA2: </b>
        <table><tr><td>
            <div class="container">
                <p>Total de Instâncias on no Setup Qa2:</p>
                <span id="totalInstancesQa2">Aguardando...</span>
            </div>
            </td><td>
            <div class="container">
                <div id="bannerQa2" class="small-banner">
                    <p>Numero de instancias:</p>
                    <p id="statusQa2">Aguardando...</p>
                </div>
            </div>
            </td><td>
              {/* Botão de atualização */}
            <button id="updateButtonQa2" onClick={fetchtotalInstancesQa2} class="button">Atualizar Status</button>

              {/* Área de mensagem */}
            <div id="messageAreaQa2"></div>
            </td>
            <td>
              <button
                id="openUrlQa1"
                onClick={openUrlQa2}
                className="button"
              >
               Open Qa2
              </button>
            </td>
          </tr>
        </table> 
      <table>
        <tr>
          <td> <div className="container2"> 
            <form id="configForm">
            <select id="desiredCapacity" name="desiredCapacity">
             <option value="1">Ligar</option>
              <option value="0">Desligar</option> 
            </select></form> </div>   </td><td>
            <button type="button" onClick={() => updateAutoScalingQa2(user.attributes.email)}>Enviar</button>
          </td>
        </tr>
      </table>
      <p><span id="resultadoQa2"></span></p>
    </div>  

    <div className="setupdemo"> <b>Setup RC</b>
    <table>
          <tr>
            <td>
              <div className="container">
                <p>Total de Instâncias on no Setup RC:</p>
                <span id="totalInstancesRC">Aguardando...</span>
              </div>
            </td>
            <td>
              <div className="container">
                <div id="bannerRC" className="small-banner">
                  <p>Numero de instancias:</p>
                  <p id="statusRC">Aguardando...</p>
                </div>
              </div>
            </td>
            <td>
              {/* Botão de atualização */}
              <button
                id="updateButtonRC"
                onClick={fetchtotalInstancesRC}
                className="button"
              >
                Atualizar Status
              </button>

              {/* Área de mensagem */}
              <div id="messageAreaRC"></div>
            </td>
            <td>
              <button
                id="openUrlRC"
                onClick={openUrlRC}
                className="button"
              >
               Open RC
              </button>
            </td>
          </tr>
        </table>
    
      <table><tr><td><div className="container2"> 
    <form id="configFormRC">
      
        <select id="desiredCapacityRC" name="desiredCapacityRC">
            <option value="1">Ligar</option>
            <option value="0">Desligar</option>
        </select></form></div></td>
        <td>
        <button type="button"  onClick={() => updateAutoScalingRC(user.attributes.email)}> Enviar</button>
        </td></tr></table>
      <p><span id="resultadoRC"></span></p>

      </div> 

    <div className="setupdemo"> <b>Setup Dev:</b>
     <table><tr><td>
    <div class="container">
        <p>Total de Instâncias on no Setup Dev:</p>
        <span id="totalInstances">Aguardando...</span>
    </div>
    </td><td>
    <div class="container">
        <div id="banner" class="small-banner">
            <p>Numero de instancias:</p>
            <p id="status">Aguardando...</p>
        </div>
    </div>
    </td><td>
    <button id="updateButton" onClick={fetchTotalInstances} class="button">Atualizar Status</button>
    <div id="messageArea"></div>
            </td>
            <td>
              <button
                id="openUrlQa1"
                onClick={openUrlDev}
                className="button"
              >
               Open Dev
              </button>
            </td>
      </tr>
  </table>

  <table><tr><td><div className="container2"> 
    <form id="configFormDev">
        <select id="desiredCapacityDev" name="desiredCapacityDev">
            <option value="1">Ligar</option>
            <option value="0">Desligar</option>
        </select></form></div></td>
        <td>
        <button type="button"  onClick={() => updateAutoScalingDev(user.attributes.email)}> Enviar</button>
        </td></tr></table>
      <p><span id="resultadoDev"></span></p>
      
    </div>  

    
        
      </div>
    )}
  </Authenticator>
  );
}

export default App;
